var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen w-screen bg-black text-white flex flex-row justify-start items-stretch bg-cover",style:([_vm.bgImage])},[(_vm.hasVideo)?_c('video',{staticClass:"w-full h-full object-cover absolute",attrs:{"autoplay":""},domProps:{"muted":_vm.hasSound ? true : false}},[_c('source',{attrs:{"src":("https://backoffice.directtv.vilavitaparc.com/assets/" + (_vm.pageItems.video.filename_disk)),"type":_vm.pageItems.video.type}})]):_vm._e(),_c('div',{staticClass:"relative z-10 w-full h-full"},[_c('div',{staticClass:"w-2/5 h-full p-12 pr-24 bg-gradient-to-r from-dark-transparent-80 to-transparent"},[(_vm.pageItems.title)?_c('h1',{class:[
					' tracking-tighter  font-other pb-2',
					_vm.hasVideo
						? 'text-xl 2xl:text-3xl font-normal'
						: 'text-6xl 2xl:text-8xl font-extralight' ],domProps:{"innerHTML":_vm._s(_vm.pageItems.title)}}):_vm._e(),(_vm.pageItems.locations)?_c('div',{staticClass:"text-lg 2xl:text-2xl font-other py-2"},[_c('img',{staticClass:"w-8 inline-block mb-2",attrs:{"src":"/icons/location.svg"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.pageItems.locations)}})]):_vm._e(),_c('h2',{staticClass:"text-lg 2xl:text-2xl tracking-tighter font-other py-2"},[(_vm.pageItems.nextdates)?_c('div',[_vm._v(" "+_vm._s(_vm.pageItems.nextdates)+" "),(_vm.pageItems.starttimeiverride)?_c('span',[_vm._v("— "+_vm._s(_vm.pageItems.starttimeiverride))]):_c('span',[_vm._v("— "+_vm._s(_vm.pageItems.starttime))])]):_c('div',[_vm._v(" "+_vm._s(_vm.pageItems.startday)+" — "+_vm._s(_vm.pageItems.starttime)+" ")])]),(
					_vm.pageItems.nextdates &&
					_vm.pageItems.recurrent_text &&
					_vm.pageItems.nextdates !== _vm.pageItems.startday
				)?_c('div',{staticClass:"mb-8"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pageItems.recurrent_text)}})]):_vm._e(),_c('div',{staticClass:"text-xl 2xl:text-3xl font-lato py-2 text-white",domProps:{"innerHTML":_vm._s(_vm.pageItems.summary)}})]),(_vm.updatedUrl)?_c('vue-qr',{staticClass:"absolute bottom-6 right-8 2xl:bottom-0 2xl:right-0 z-50",attrs:{"text":_vm.updatedUrl,"correct-level":1,"size":240}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }