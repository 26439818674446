<template>
	<div
		class="h-screen w-screen bg-black text-white flex flex-row justify-start items-stretch bg-cover"
		:style="[bgImage]"
	>
		<video
			v-if="hasVideo"
			:muted="hasSound ? true : false"
			autoplay
			class="w-full h-full object-cover absolute"
		>
			<source
				:src="`https://backoffice.directtv.vilavitaparc.com/assets/${pageItems.video.filename_disk}`"
				:type="pageItems.video.type"
			/>
		</video>
		<div class="relative z-10 w-full h-full">
			<div
				class="w-2/5 h-full p-12 pr-24 bg-gradient-to-r from-dark-transparent-80 to-transparent"
			>
				<h1
					v-if="pageItems.title"
					:class="[
						' tracking-tighter  font-other pb-2',
						hasVideo
							? 'text-xl 2xl:text-3xl font-normal'
							: 'text-6xl 2xl:text-8xl font-extralight',
					]"
					v-html="pageItems.title"
				></h1>

				<!-- <h2
					v-if="pageItems.nextdates || pageItems.dateend"
					class="text-2xl tracking-tighter font-other py-2"
				>
					<span v-if="pageItems.nextdates && !pageItems.dateend">
						{{ dateConvert(pageItems.nextdates) }}
					</span>
					<span v-if="!pageItems.nextdates && pageItems.dateend">{{
						pageItems.dateend !== "Custom/Single Event" ? pageItems.dateend : ""
					}}</span>
					<span v-if="pageItems.nextdates && pageItems.dateend"
						>{{ dateConvert(pageItems.nextdates) }}
						{{
							pageItems.dateend !== "Custom/Single Event"
								? "&mdash; " + pageItems.dateend
								: ""
						}}</span
					>
				</h2> -->

				<div
					v-if="pageItems.locations"
					class="text-lg 2xl:text-2xl font-other py-2"
				>
					<img src="/icons/location.svg" class="w-8 inline-block mb-2" />
					<span v-html="pageItems.locations"></span>
				</div>

				<h2 class="text-lg 2xl:text-2xl tracking-tighter font-other py-2">
					<div v-if="pageItems.nextdates">
						{{ pageItems.nextdates }}
						<span v-if="pageItems.starttimeiverride"
							>&mdash; {{ pageItems.starttimeiverride }}</span
						><span v-else>&mdash; {{ pageItems.starttime }}</span>
					</div>

					<div v-else>
						{{ pageItems.startday }} &mdash; {{ pageItems.starttime }}
					</div>
				</h2>

				<div
					v-if="
						pageItems.nextdates &&
						pageItems.recurrent_text &&
						pageItems.nextdates !== pageItems.startday
					"
					class="mb-8"
				>
					<span v-html="pageItems.recurrent_text"></span>
				</div>

				<div
					class="text-xl 2xl:text-3xl font-lato py-2 text-white"
					v-html="pageItems.summary"
				></div>
			</div>

			<vue-qr
				class="absolute bottom-6 right-8 2xl:bottom-0 2xl:right-0 z-50"
				v-if="updatedUrl"
				:text="updatedUrl"
				:correct-level="1"
				:size="240"
			/>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs"
import VueQr from "vue-qr"

export default {
	components: {
		VueQr,
	},

	props: {
		pageItems: {
			type: Object,
			required: true,
		},
		hasSound: {
			type: Boolean,
			default: false,
		},
	},

	mounted() {
		setTimeout(() => {
			this.$emit("nextpage")
		}, 30000)
	},

	computed: {
		bgImage() {
			let image
			if (this.pageItems) {
				image = this.pageItems.imageurl_hero
					? {
							backgroundImage: `url(${this.pageItems.imageurl_hero})`,

							// eslint-disable-next-line
					  }
					: {
							backgroundImage: `url(/imgs/default_vvp.jpg)`,
							// eslint-disable-next-line
					  }
			}

			return image
		},

		updatedUrl() {
			let finalUrl = undefined
			const title = (this.pageItems.title || "no_title").replaceAll(" ", "_")
			const analyticsExtra = `utm_source=lobby_tv&utm_medium=tv&utm_campaign=${title}`
			if (this.pageItems && this.pageItems.linkpath) {
				if (!this.pageItems.linkpath.includes("https://")) {
					finalUrl = `https://vilavitaparc.com${this.pageItems.linkpath}`
				} else {
					finalUrl = this.pageItems.linkpath
				}

				if (finalUrl.includes("?")) {
					finalUrl = `${finalUrl}&${analyticsExtra}`
				} else {
					finalUrl = `${finalUrl}?${analyticsExtra}`
				}
			}

			return finalUrl || undefined
		},

		hasVideo() {
			if (this.pageItems.video && this.pageItems.video.type === "video/mp4") {
				return true
			}
			return false
		},
	},

	methods: {
		dateConvert(date, type = "nextdate") {
			let data
			// if (type === "time") {
			// 	// const time = new Date(timestamp * 1000)
			// 	data = dayjs(timestamp * 1000).format("hh:mm")
			// 	// data = time.toLocaleTimeString().substring(0, 5)
			// }

			if (type === "date") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)
				data = dayjs(date).format("D MMMM")
			}

			if (type === "nextdate") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)

				const tempdate = date.slice(0, 19)
				data = dayjs(tempdate).format("dddd,  D MMMM YYYY")
			}

			// if (type === "dateweek") {
			// 	// const date = new Date(timestamp * 1000)
			// 	// const date = dayjs(timestamp)
			// 	data = dayjs(timestamp * 1000).format("ddd  D MMM")
			// }
			// if (type === "weekday") {
			// 	// const date = new Date(timestamp * 1000)
			// 	// const date = dayjs(timestamp)
			// 	data = dayjs(timestamp * 1000).format("ddd")
			// }

			return data
		},
	},
}
</script>

<style scoped>
::v-deep p {
	padding-bottom: 18px;
}
</style>
