<template>
	<div
		class="h-screen w-screen bg-red-500 text-white flex flex-col justify-center bg-cover pb-40"
		:style="[bgImage]"
	>
		<div class="w-full text-center font-sans text-2xl uppercase my-12">
			7 days forecast
		</div>

		<div class="w-full grid grid-cols text-2xl px-8 2xl:px-24 py-12">
			<div class="text-center grid grid-cols-7 gap-2">
				<div v-for="day in realDailyWeather" :key="day.dt" class="2xl:w-48">
					<div
						class="font-other uppercase text-lg bg-dark-transparent-45 p-4 rounded-t-xl"
					>
						{{ dateConvert(day.dt, "weekday") }}
						<br />
						{{ dateConvert(day.dt, "date") }}
					</div>
					<div
						class="uppercase bg-dark-transparent-45 2xl:bg-dark-transparent-25 p-4 rounded-b-xl leading-loose text-lg 2xl:text-3xl"
					>
						<div>
							<img
								:src="
									loadOpenWeatherIcon(
										weatherData.current.weather[0].icon,
										'fill'
									)
								"
								class="block object-contain w-full h-full p-4 -mt-4 -mb-4"
							/>
						</div>
						<div class="">{{ temperatureCelsius(day.temp.day) }}&deg; C</div>
						<div
							class="leading-none py-3 flex flex-row text-lg w-full justify-center"
						>
							<div
								class="bg-red-700 px-3 py-2 rounded-l-xl text-right bg-opacity-75"
							>
								<div>{{ temperatureCelsius(day.temp.max) }}&deg; C</div>
								<div class="text-xs opacity-75 tracking-widest mt-0.5">Max</div>
							</div>
							<div
								class="bg-blue-700 px-3 py-2 rounded-r-xl text-left bg-opacity-75"
							>
								<div>{{ temperatureCelsius(day.temp.min) }}&deg; C</div>
								<div class="text-xs opacity-75 tracking-widest mt-0.5">min</div>
							</div>
						</div>

						<div class="leading-none py-3">
							<div>{{ Math.round(day.uvi) }}</div>
							<div class="text-xs opacity-50 tracking-widest">UV index</div>
						</div>
						<div class="leading-none py-3">
							<div>
								{{ Math.round(day.humidity)
								}}<span class="lowercase text-lg"> %</span>
							</div>
							<div class="text-xs opacity-50 tracking-widest">Humidity</div>
						</div>
						<div class="leading-none py-3">
							<div>
								{{ Math.round(day.wind_speed)
								}}<span class="lowercase text-lg"> m/s</span>
							</div>
							<div class="text-xs opacity-50 tracking-widest">Wind speed</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs"

export default {
	props: {
		weatherData: {
			type: Object,
			required: true,
		},

		pageItems: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {}
	},

	mounted() {
		setTimeout(() => {
			this.$emit("nextpage")
		}, 30000)
	},

	computed: {
		bgImage() {
			const backofficePath =
				"https://backoffice.directtv.vilavitaparc.com/assets"

			let image
			if (this.pageItems) {
				image =
					this.pageItems.image && this.pageItems.image.filesize
						? {
								backgroundImage: `url(${backofficePath}/${this.pageItems.image.filename_disk}?key=full-hd)`,
								// eslint-disable-next-line
						  }
						: {
								backgroundImage: `url(/imgs/default_vvp.jpg)`,
								// eslint-disable-next-line
						  }
			}

			return image
		},

		realDailyWeather() {
			const final = []

			if (
				this.weatherData &&
				this.weatherData.daily &&
				this.weatherData.daily.length > 0
			) {
				this.weatherData.daily.forEach((day, idx) => {
					if (idx > 0 && idx < 8) {
						final.push(day)
					}
				})
			}

			return final
		},

		sidepanel() {
			return [
				{
					type: "uv",
					icon: "uv-index",
					iconStyle: "fill", // can be 'line' or 'fill'. Undefined defaults to 'line'
					objPath: this.weatherData.current.uvi,
					iconText: "",
				},
				{
					type: "percentage",
					icon: "humidity",
					iconStyle: undefined,
					objPath: this.weatherData.current.humidity,
					iconText: "Humidity",
				},
				{
					type: "speed",
					icon: "windsock",
					iconStyle: undefined,
					objPath: this.weatherData.current.wind_speed,
					iconText: "Wind speed",
				},
				{
					type: "temperature",
					icon: "thermometer-max",
					iconStyle: undefined,
					objPath: this.weatherData.daily[0].temp.max,
					iconText: "Max.",
				},
				{
					type: "temperature",
					icon: "thermometer-min",
					iconStyle: undefined,
					objPath: this.weatherData.daily[0].temp.min,
					iconText: "Min.",
				},
				{
					type: "time",
					icon: "sunrise",
					iconStyle: "fill",
					objPath: this.weatherData.current.sunrise,
					iconText: "Sunrise",
				},
				{
					type: "time",
					icon: "sunset",
					iconStyle: "fill",
					objPath: this.weatherData.current.sunset,
					iconText: "Sunset",
				},
			]
		},
	},

	methods: {
		dateConvert(timestamp, type = "time") {
			let data
			if (type === "time") {
				// const time = new Date(timestamp * 1000)
				data = dayjs(timestamp * 1000).format("hh:mm")
				// data = time.toLocaleTimeString().substring(0, 5)
			}

			if (type === "date") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)
				data = dayjs(timestamp * 1000).format("D MMM")
			}

			if (type === "dateweek") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)
				data = dayjs(timestamp * 1000).format("ddd  D MMM")
			}
			if (type === "weekday") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)
				data = dayjs(timestamp * 1000).format("ddd")
			}

			return data
		},

		temperature(value) {
			return {
				c: Math.round(value),
				f: Math.round(value * 1.8 + 32),
			}
		},

		temperatureCelsius(value) {
			return Math.round(value)
		},

		metersToKm(value) {
			return value / 1000
		},

		loadOpenWeatherIcon(iconCode, style = "line") {
			return `/icons/weather-icons-master/production/${style}/openweathermap/${iconCode}.svg`
		},
		loadOtherIcon(iconCode, style = "line") {
			return `/icons/weather-icons-master/production/${style}/all/${iconCode}.svg`
		},
	},
}
</script>

<style scoped></style>
