<template>
	<div
		class="h-screen w-screen bg-red-500 text-white flex flex-col justify-center bg-cover"
		:style="[bgImage]"
	>
		<div class="w-full text-center font-sans">
			<div
				class="grid grid-cols-7 place-items-start place-content-stretch justify-self-start mb-16 2xl:mb-64"
			>
				<div class="w-full h-full col-span-2">
					<img
						:src="loadOpenWeatherIcon(weatherData.current.weather[0].icon)"
						class="-mt-16 block w-full h-full object-contain items-start"
					/>
					<!-- <div class="text-xl opacity-90 tracking-wide">
						<div class="pb-6 capitalize">
							{{ weatherData.current.weather[0].description }}
						</div>
					</div> -->
				</div>

				<div class="text-center justify-self-center col-span-3">
					<div>
						<div
							class="text-10xl 2xl:text-16xl font-thin font-lato tracking-tighter pb-4 leading-none"
						>
							{{ temperature(weatherData.current.temp).c }}&deg; C
						</div>
						<div class="pb-6 capitalize">
							{{ weatherData.current.weather[0].description }}
						</div>
						<div class="mt-32 uppercase font-poppins">
							<div class="inline-grid grid-cols-4">
								<div
									class="bg-dark-transparent-25 rounded-2xl backdrop-filter backdrop-blur-md w-32 mx-4"
								>
									<div
										class="font-other uppercase text-xl bg-dark-transparent-35 p-4 rounded-t-2xl"
									>
										Morning
									</div>
									<div class="text-3xl font-thin px-5 py-5">
										{{ temperature(weatherData.daily[0].temp.morn).c }}&deg; C
									</div>
								</div>

								<div
									class="bg-dark-transparent-25 rounded-2xl backdrop-filter backdrop-blur-md w-32 mx-4"
								>
									<div
										class="font-other uppercase text-xl bg-dark-transparent-35 p-4 rounded-t-2xl"
									>
										Day
									</div>
									<div class="text-3xl font-thin px-5 py-5">
										{{ temperature(weatherData.daily[0].temp.day).c }}&deg; C
									</div>
								</div>

								<div
									class="bg-dark-transparent-25 rounded-2xl backdrop-filter backdrop-blur-md w-32 mx-4"
								>
									<div
										class="font-other uppercase text-xl bg-dark-transparent-35 p-4 rounded-t-2xl"
									>
										Evening
									</div>
									<div class="text-3xl font-thin px-5 py-5">
										{{ temperature(weatherData.daily[0].temp.eve).c }}&deg; C
									</div>
								</div>

								<div
									class="bg-dark-transparent-25 rounded-2xl backdrop-filter backdrop-blur-md w-32 mx-4"
								>
									<div
										class="font-other uppercase text-xl bg-dark-transparent-35 p-4 rounded-t-2xl"
									>
										Night
									</div>
									<div class="text-3xl font-thin px-5 py-5">
										{{ temperature(weatherData.daily[0].temp.night).c }}&deg; C
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="text-3xl col-span-2 leading-loose text-left self-start justify-self-center grid grid-cols-2 bg-dark-transparent-45 px-5 py-5 rounded-2xl backdrop-filter backdrop-blur-xl"
				>
					<template v-for="(item, index) in sidepanel">
						<div
							class="text-center w-20 justify-self-end mt-4"
							:key="'icon' + index"
						>
							<img
								:src="loadOtherIcon(item.icon, item.iconStyle)"
								class="mx-auto w-16 block -mt-2"
							/>
							<div class="text-xs -mt-3 opacity-50">
								{{ item.iconText }}
							</div>
						</div>
						<div :key="'result' + index">
							<div
								v-if="
									item.type !== 'temperature' &&
									item.type !== 'date' &&
									item.type !== 'time' &&
									item.type !== 'speed' &&
									item.type !== 'percentage'
								"
								class="mt-4"
							>
								{{ item.objPath }}
							</div>
							<div v-if="item.type === 'temperature'" class="mt-4">
								{{ temperature(item.objPath).c }}&deg; C
							</div>
							<div v-if="item.type === 'speed'" class="mt-4">
								{{ Math.round(item.objPath) }} m/s
							</div>
							<div v-if="item.type === 'percentage'" class="mt-4">
								{{ Math.round(item.objPath) }} %
							</div>
							<div v-if="item.type === 'time'" class="mt-4">
								{{ dateConvert(item.objPath, "time") }}
							</div>
							<div v-if="item.type === 'date'" class="mt-4">
								{{ dateConvert(item.objPath, "date") }}
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		weatherData: {
			type: Object,
			required: true,
		},

		pageItems: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {}
	},

	mounted() {
		setTimeout(() => {
			this.$emit("nextpage")
		}, 30000)
	},

	computed: {
		bgImage() {
			const backofficePath =
				"https://backoffice.directtv.vilavitaparc.com/assets"

			let image
			if (this.pageItems) {
				image =
					this.pageItems.image && this.pageItems.image.filesize
						? {
								backgroundImage: `url(${backofficePath}/${this.pageItems.image.filename_disk}?key=full-hd)`,
								// eslint-disable-next-line
						  }
						: {
								backgroundImage: `url(/imgs/default_vvp.jpg)`,
								// eslint-disable-next-line
						  }
			}

			return image
		},

		sidepanel() {
			return [
				{
					type: "uv",
					icon: "uv-index",
					iconStyle: "fill", // can be 'line' or 'fill'. Undefined defaults to 'line'
					objPath: this.weatherData.current.uvi,
					iconText: "",
				},
				{
					type: "percentage",
					icon: "humidity",
					iconStyle: undefined,
					objPath: this.weatherData.current.humidity,
					iconText: "Humidity",
				},
				{
					type: "speed",
					icon: "windsock",
					iconStyle: undefined,
					objPath: this.weatherData.current.wind_speed,
					iconText: "Wind speed",
				},
				{
					type: "temperature",
					icon: "thermometer-max",
					iconStyle: undefined,
					objPath: this.weatherData.daily[0].temp.max,
					iconText: "Max.",
				},
				{
					type: "temperature",
					icon: "thermometer-min",
					iconStyle: undefined,
					objPath: this.weatherData.daily[0].temp.min,
					iconText: "Min.",
				},
				{
					type: "time",
					icon: "sunrise",
					iconStyle: "fill",
					objPath: this.weatherData.current.sunrise,
					iconText: "Sunrise",
				},
				{
					type: "time",
					icon: "sunset",
					iconStyle: "fill",
					objPath: this.weatherData.current.sunset,
					iconText: "Sunset",
				},
			]
		},
	},

	methods: {
		dateConvert(timestamp, type = "time") {
			let data
			if (type === "time") {
				data = new Date(timestamp * 1000).toLocaleTimeString().substring(0, 5)
			}

			if (type === "date") {
				data = new Date(timestamp * 1000).toLocaleDateString()
			}

			return data
		},

		temperature(value) {
			return {
				c: Math.round(value),
				f: Math.round(value * 1.8 + 32),
			}
		},

		metersToKm(value) {
			return value / 1000
		},

		loadOpenWeatherIcon(iconCode, style = "line") {
			return `/icons/weather-icons-master/production/${style}/openweathermap/${iconCode}.svg`
		},
		loadOtherIcon(iconCode, style = "line") {
			return `/icons/weather-icons-master/production/${style}/all/${iconCode}.svg`
		},
	},
}
</script>

<style scoped></style>
