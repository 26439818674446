<template>
	<div
		class="h-screen w-screen bg-black text-white flex flex-row justify-start items-stretch bg-cover"
		:style="[bgImage]"
	>
		<video
			v-if="hasVideo"
			:muted="hasSound ? false : true"
			autoplay
			class="w-full h-full object-cover absolute"
			ref="thevideo"
		>
			<source
				:src="`https://backoffice.directtv.vilavitaparc.com/assets/${pageItems.video.filename_disk}`"
				:type="pageItems.video.type"
			/>
		</video>
		<div class="relative z-10 w-full h-full">
			<div
				class="w-2/5 h-full p-12 pr-24 bg-gradient-to-r from-dark-transparent-50 to-transparent"
			>
				<h1
					v-if="pageItems.title"
					:class="[
						' tracking-tighter  font-other pb-2',
						hasVideo
							? 'text-xl 2xl:text-3xl font-normal'
							: 'text-6xl 2xl:text-8xl font-extralight',
					]"
				>
					{{ pageItems.title }}
				</h1>
				<h2
					v-if="pageItems.datestart || pageItems.dateend"
					class="text-xl 2xl:text-2xl tracking-tighter font-other py-2"
				>
					<span v-if="pageItems.datestart && !pageItems.dateend">{{
						dateConvert(pageItems.datestart)
					}}</span>
					<span v-if="!pageItems.datestart && pageItems.dateend">{{
						dateConvert(pageItems.dateend)
					}}</span>
					<span v-if="pageItems.datestart && pageItems.dateend"
						>{{ dateConvert(pageItems.datestart) }} &mdash;
						{{ dateConvert(pageItems.dateend) }}</span
					>
				</h2>
				<div
					v-if="pageItems.description"
					class="text-xl 2xl:text-3xl font-lato font-light py-2 text-white"
					v-html="pageItems.description"
				></div>
			</div>

			<vue-qr
				class="absolute bottom-6 right-8 2xl:bottom-0 2xl:right-0 z-50"
				v-if="updatedUrl"
				:text="updatedUrl"
				:correct-level="1"
				:size="240"
			/>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs"
import VueQr from "vue-qr"

export default {
	components: {
		VueQr,
	},

	props: {
		pageItems: {
			type: Object,
			required: true,
		},
		hasSound: {
			type: Boolean,
			default: false,
		},
	},

	mounted() {
		setTimeout(() => {
			if (!this.hasVideo)
				setTimeout(() => {
					this.$emit("nextpage")
				}, 30000)
			else {
				const ref = this.$refs.thevideo
				ref.onended = () => {
					this.$emit("nextpage")
				}
			}
		}, 1000)
	},

	computed: {
		bgImage() {
			const backofficePath =
				"https://backoffice.directtv.vilavitaparc.com/assets"

			let image
			if (this.pageItems) {
				image =
					this.pageItems.image && this.pageItems.image.filesize
						? {
								backgroundImage: `url(${backofficePath}/${this.pageItems.image.filename_disk}?key=full-hd)`,

								// eslint-disable-next-line
						  }
						: {
								backgroundImage: `url(/imgs/default_vvp.jpg)`,
								// eslint-disable-next-line
						  }
			}

			return image
		},

		updatedUrl() {
			let finalUrl = undefined
			const title = (this.pageItems.title || "no_title").replaceAll(" ", "_")
			const analyticsExtra = `utm_source=lobby_tv&utm_medium=tv&utm_campaign=${title}`
			if (this.pageItems && this.pageItems.url) {
				if (this.pageItems.url.includes("?")) {
					finalUrl = `${this.pageItems.url}&${analyticsExtra}`
				} else {
					finalUrl = `${this.pageItems.url}?${analyticsExtra}`
				}
			}

			return finalUrl || undefined
		},

		hasVideo() {
			if (this.pageItems.video && this.pageItems.video.type === "video/mp4") {
				return true
			}
			return false
		},
	},

	methods: {
		dateConvert(date, type = "date") {
			let data
			// if (type === "time") {
			// 	// const time = new Date(timestamp * 1000)
			// 	data = dayjs(timestamp * 1000).format("hh:mm")
			// 	// data = time.toLocaleTimeString().substring(0, 5)
			// }

			if (type === "date") {
				// const date = new Date(timestamp * 1000)
				// const date = dayjs(timestamp)
				data = dayjs(date).format("D MMMM")
			}

			// if (type === "dateweek") {
			// 	// const date = new Date(timestamp * 1000)
			// 	// const date = dayjs(timestamp)
			// 	data = dayjs(timestamp * 1000).format("ddd  D MMM")
			// }
			// if (type === "weekday") {
			// 	// const date = new Date(timestamp * 1000)
			// 	// const date = dayjs(timestamp)
			// 	data = dayjs(timestamp * 1000).format("ddd")
			// }

			return data
		},
	},
}
</script>

<style scoped>
::v-deep p {
	padding-bottom: 18px;
}
</style>
