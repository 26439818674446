<template>
	<div id="app">
		<div
			class="absolute bg-light-transparent-20 top-0 right-0 z-50 text-xl p-4"
			v-show="pageHelpVisible"
		>
			Page {{ currentPageIndex + 1 }} of {{ allPages.length }}
			<!-- <button
				type="button"
				@click="updateCurrentPage('previous')"
				@keydown.412.native="updateCurrentPage('previous')"
				@keypress.412.native="updateCurrentPage('previous')"
				@keyup.412.native="updateCurrentPage('previous')"
				class="bg-gray-200 p-1 mx-2"
			>
				&lt;
			</button>
			<button
				type="button"
				@click="updateCurrentPage('next')"
				@keyup.417.native="updateCurrentPage('next')"
				@keypress.417.native="updateCurrentPage('next')"
				@keydown.417.native="updateCurrentPage('next')"
				class="bg-gray-200 p-1 mx-2"
			>
				&gt;
			</button> -->
			<!-- &nbsp; &nbsp; lastkey key: {{ lastkey.key }} code:
			{{ lastkey.code }} keyCode: {{ lastkey.keyCode }} -->

			<!-- &nbsp; &nbsp; &nbsp; w: {{ windowSize.width }}, h: w:
			{{ windowSize.height }} -->
		</div>
		<transition name="fade" appear>
			<div
				v-if="!pageIsReady"
				key="loading"
				class="absolute w-full h-full flex flex-row justify-center items-center"
			>
				<div>Loading, please wait</div>
			</div>
			<div
				v-else-if="pageIsReady && interactionRequest"
				key="manualstartsound"
				class="absolute w-full h-full flex flex-row justify-center items-center"
			>
				<div>
					<button
						class="py-3 px-6 rounded bg-blue-500 text-white uppercase font-bold"
						@click="
							interactionRequest = false
							hasSound = true
						"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							fill="#000000"
							version="1.1"
							id="Capa_1"
							width="800px"
							height="800px"
							viewBox="0 0 96.155 96.155"
							xml:space="preserve"
							class="h-5 inline-block fill-current w-5 mr-3 pb-1"
						>
							<g>
								<path
									d="M20.972,95.594l57.605-45.951c0.951-0.76,0.951-2.367,0-3.127L20.968,0.56c-0.689-0.547-1.716-0.709-2.61-0.414   c-0.186,0.061-0.33,0.129-0.436,0.186c-0.65,0.35-1.056,1.025-1.056,1.764v91.967c0,0.736,0.405,1.414,1.056,1.762   c0.109,0.06,0.253,0.127,0.426,0.185C19.251,96.305,20.281,96.144,20.972,95.594z"
								/>
							</g>
						</svg>

						Start presentation
					</button>
				</div>
			</div>
			<div v-else>
				<div
					v-for="(page, index) in allPages"
					:key="page.id"
					class="h-full w-full"
				>
					<transition name="fade" appear>
						<component
							:is="page.item.componentName"
							v-if="
								index === currentPageIndex &&
								page.item &&
								page.item.componentName &&
								(page.item.componentName === 'WeatherForecast1' ||
									page.item.componentName === 'WeatherForecastNextdays')
							"
							:weather-data="weatherData"
							:page-items="page.item"
							:has-sound="hasSound"
							class="h-screen w-screen absolute"
							@nextpage="updateCurrentPage('next')"
						>
							{{ page.item }}
						</component>
						<component
							:is="page.item.componentName"
							v-if="
								index === currentPageIndex &&
								page.item &&
								page.item.componentName &&
								page.item.componentName === 'publicPage'
							"
							:page-items="page.item"
							:has-sound="hasSound"
							@nextpage="updateCurrentPage('next')"
							class="h-screen w-screen absolute"
						>
							{{ page.item }}
						</component>
						<component
							:is="page.componentName"
							v-if="
								index === currentPageIndex &&
								!page.item &&
								page.componentName &&
								page.componentName === 'eventsPage'
							"
							:page-items="page"
							:has-sound="hasSound"
							@nextpage="updateCurrentPage('next')"
							class="h-screen w-screen absolute"
						>
							{{ page.item }}
						</component>
					</transition>
					<div
						v-if="
							!page.componentName &&
							page.item &&
							!page.item.componentName &&
							index === currentPageIndex
						"
						:has-sound="hasSound"
						class="h-screen w-screen absolute"
						@nextpage="updateCurrentPage('next')"
					>
						{{ page.item }}
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
const mainQuery =
	"https://backoffice.directtv.vilavitaparc.com/items/finalComposition?fields[]=id&fields[]=components.*&fields[]=components.item.*&fields[]=components.item.image.*&fields[]=components.item.video.*"

const eventsQuery = `https://backoffice.vilavitaparc.com/en/rest/upcomingevents2?version=${Date.now()}`

// const eventsDateInstancesQuery = `https://backoffice.vilavitaparc.com/en/rest/upcomingevents2_instances`

const weatherQuery =
	"https://api.openweathermap.org/data/2.5/onecall?lat=37.101932&lon=-8.379493&appid=7f6996b80b3b64bfc9541ad3f7f912ff&lang=en&units=metric"

const dateNow = new Date()

// import axios from "axios"

import WeatherForecast1 from "./components/weatherForecast1.vue"
import WeatherForecastNextdays from "./components/weatherForecast2.vue"
import publicPage from "./components/publicPage.vue"
import eventsPage from "./components/eventsPage.vue"
import _uniqBy from "lodash/uniqBy"

export default {
	name: "App",

	components: {
		WeatherForecast1,
		WeatherForecastNextdays,
		publicPage,
		eventsPage,
	},

	data() {
		return {
			pageIsReady: false,

			allPages: [],
			currentPageIndex: 0,

			weatherData: {},

			lastkey: {},
			pageHelpVisible: false,

			pageChannel: null,
			interactionRequest: false,
			hasSound: false,
		}
	},

	created() {
		window.addEventListener("keydown", (event) => {
			if (
				event.code == "F1" ||
				event.code == "ArrowLeft" ||
				event.code == "ArrowUp" ||
				event.code == "PageDown"
			) {
				this.updateCurrentPage("previous")
			}
			if (
				event.code == "F4" ||
				event.code == "ArrowRight" ||
				event.code == "ArrowDown" ||
				event.code == "PageUp"
			) {
				this.updateCurrentPage("next")
			}
			this.lastkey = event
			this.pageHelpVisible = true
			setTimeout(() => {
				this.pageHelpVisible = false
			}, 3000)
		})

		// reload page every x minutes

		const minutes = 30
		setTimeout(() => {
			// window.location.reload()
			this.pageIsReady = false
			this.loadData()
			setTimeout(() => {
				this.pageIsReady = true
			}, 5000)
		}, minutes * 60 * 1000)
	},

	mounted() {
		this.loadData()
		const searchURL = window.location.search
		const urlParams = new URLSearchParams(searchURL)
		this.pageChannel = urlParams.get("channel")

		if (this.pageChannel === "room") {
			this.interactionRequest = true
		}

		// setInterval(() => {
		// 	this.updateCurrentPage()
		// }, 30 * 1000)

		setTimeout(() => {
			this.pageIsReady = true
		}, 5000)
	},

	computed: {
		maxLengthOfPages: {
			get: function () {
				return this.allPages && this.allPages.length ? this.allPages.length : 0
			},
			set: function () {
				return this.allPages && this.allPages.length ? this.allPages.length : 0
			},
		},

		windowSize() {
			const width = screen.width || null
			const height = screen.height || null
			return {
				width: width,
				height: height,
			}
		},
	},

	methods: {
		loadData() {
			fetch(mainQuery)
				.then((result) => result.json())
				.then((data) => {
					// console.log("result:", { data })
					const rawdata = data.data.components

					// clean up drafts and archived pages

					const finaldata = []
					const pagesToRemove = []

					rawdata.forEach((each) => {
						const archiveDate =
							each.item && each.dateToArchive ? each.dateToArchive : null
						if (
							each.item &&
							each.item.status === "published" &&
							(new Date(archiveDate) > new Date(dateNow) || !archiveDate)
						) {
							// console.log("each", each)
							// console.log("now is: ", new Date(dateNow))
							// TODO: Remove them from server for posterity
							finaldata.push(each)
						} else {
							pagesToRemove.push(each)
							console.log("pagesToRemove", pagesToRemove)
						}
					})

					this.allPages = finaldata
					this.maxLengthOfPages = finaldata.length
					return finaldata
				})

				.then((pageStructure) => {
					if (
						pageStructure.some(
							(each) =>
								each.item &&
								each.item.title &&
								(each.item.componentName.includes("WeatherForecast1") ||
									each.item.componentName.includes("WeatherForecastNextdays"))
						)
					) {
						fetch(weatherQuery)
							.then((result) => result.json())
							.then((data) => {
								// console.log("result weather:", data)
								this.weatherData = data
							})
					}
				})
				.then(() => {
					fetch(eventsQuery)
						.then((result) => result.json())
						.then((eventsResult) => {
							// console.log("result events", eventsResult)

							const temp = eventsResult.map((obj) => {
								obj.componentName = "eventsPage"
								const objCopy = obj
								if (objCopy.class != null || objCopy.class !== undefined) {
									objCopy.classe = objCopy.class
									delete objCopy.class
								}
								return objCopy
							})
							// console.log("obj", temp)

							// Removing duplicates by nid,
							// because Drupal rest brings all instances of days

							const collection = _uniqBy(temp, "nid")

							// console.log("collection", collection)

							// const getIndexOfEvents = () =>
							// 	this.allPages.findIndex((eachObj) => {
							// 		return (
							// 			eachObj.item &&
							// 			eachObj.item.componentName === "events-vvpwebsite"
							// 		)
							// 	})

							// console.log("index of eventsvvp", getIndexOfEvents())

							// replace the allPages array with a new one, where the 'index of eventsvvp' is replaced with all events

							const newFinalAllPages = []

							this.allPages.forEach((eachItem) => {
								if (
									eachItem.item &&
									eachItem.item.componentName !== "events-vvpwebsite"
								) {
									newFinalAllPages.push(eachItem)
								} else {
									newFinalAllPages.push(...collection)
								}
							})

							// console.log("newFinalAllPages", newFinalAllPages)

							return newFinalAllPages

							// return collection
						})
						.then((result) => {
							this.allPages = result
						})
				})
		},

		updateCurrentPage(direction = "next") {
			if (this.maxLengthOfPages && this.maxLengthOfPages > 0) {
				if (direction === "next") {
					if (
						this.currentPageIndex >= 0 &&
						this.currentPageIndex < this.maxLengthOfPages - 1
					) {
						this.currentPageIndex++
					} else {
						this.currentPageIndex = 0 // Goes to beginning
					}
				}

				if (direction === "previous") {
					if (
						this.currentPageIndex > 0 &&
						this.currentPageIndex <= this.maxLengthOfPages - 1 &&
						direction === "previous"
					) {
						this.currentPageIndex--
					} else {
						this.currentPageIndex = this.maxLengthOfPages - 1 // Goes to beginning
					}
				}
			}
		},

		handleKeydown(event) {
			return (this.lastkey = event)
		},
	},
}
</script>

<style>
#app {
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	/* color: #2c3e50; */
	/* margin-top: 60px; */
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
	transition: all 1s ease;
}
.fade-leave-active {
	transition: all 1s;
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
	/* transform: translateX(10px); */
	opacity: 0;
}
</style>
