import Vue from "vue"
import App from "./App.vue"
import VueRouter from "vue-router"
import "./assets/css/global.css"
import "./tailwind.css"

Vue.config.productionTip = false

new Vue({
	router: VueRouter,
	render: (h) => h(App),
}).$mount("#app")
