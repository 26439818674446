var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageHelpVisible),expression:"pageHelpVisible"}],staticClass:"absolute bg-light-transparent-20 top-0 right-0 z-50 text-xl p-4"},[_vm._v(" Page "+_vm._s(_vm.currentPageIndex + 1)+" of "+_vm._s(_vm.allPages.length)+" ")]),_c('transition',{attrs:{"name":"fade","appear":""}},[(!_vm.pageIsReady)?_c('div',{key:"loading",staticClass:"absolute w-full h-full flex flex-row justify-center items-center"},[_c('div',[_vm._v("Loading, please wait")])]):(_vm.pageIsReady && _vm.interactionRequest)?_c('div',{key:"manualstartsound",staticClass:"absolute w-full h-full flex flex-row justify-center items-center"},[_c('div',[_c('button',{staticClass:"py-3 px-6 rounded bg-blue-500 text-white uppercase font-bold",on:{"click":function($event){_vm.interactionRequest = false
						_vm.hasSound = true}}},[_c('svg',{staticClass:"h-5 inline-block fill-current w-5 mr-3 pb-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","fill":"#000000","version":"1.1","id":"Capa_1","width":"800px","height":"800px","viewBox":"0 0 96.155 96.155","xml:space":"preserve"}},[_c('g',[_c('path',{attrs:{"d":"M20.972,95.594l57.605-45.951c0.951-0.76,0.951-2.367,0-3.127L20.968,0.56c-0.689-0.547-1.716-0.709-2.61-0.414   c-0.186,0.061-0.33,0.129-0.436,0.186c-0.65,0.35-1.056,1.025-1.056,1.764v91.967c0,0.736,0.405,1.414,1.056,1.762   c0.109,0.06,0.253,0.127,0.426,0.185C19.251,96.305,20.281,96.144,20.972,95.594z"}})])]),_vm._v(" Start presentation ")])])]):_c('div',_vm._l((_vm.allPages),function(page,index){return _c('div',{key:page.id,staticClass:"h-full w-full"},[_c('transition',{attrs:{"name":"fade","appear":""}},[(
							index === _vm.currentPageIndex &&
							page.item &&
							page.item.componentName &&
							(page.item.componentName === 'WeatherForecast1' ||
								page.item.componentName === 'WeatherForecastNextdays')
						)?_c(page.item.componentName,{tag:"component",staticClass:"h-screen w-screen absolute",attrs:{"weather-data":_vm.weatherData,"page-items":page.item,"has-sound":_vm.hasSound},on:{"nextpage":function($event){return _vm.updateCurrentPage('next')}}},[_vm._v(" "+_vm._s(page.item)+" ")]):_vm._e(),(
							index === _vm.currentPageIndex &&
							page.item &&
							page.item.componentName &&
							page.item.componentName === 'publicPage'
						)?_c(page.item.componentName,{tag:"component",staticClass:"h-screen w-screen absolute",attrs:{"page-items":page.item,"has-sound":_vm.hasSound},on:{"nextpage":function($event){return _vm.updateCurrentPage('next')}}},[_vm._v(" "+_vm._s(page.item)+" ")]):_vm._e(),(
							index === _vm.currentPageIndex &&
							!page.item &&
							page.componentName &&
							page.componentName === 'eventsPage'
						)?_c(page.componentName,{tag:"component",staticClass:"h-screen w-screen absolute",attrs:{"page-items":page,"has-sound":_vm.hasSound},on:{"nextpage":function($event){return _vm.updateCurrentPage('next')}}},[_vm._v(" "+_vm._s(page.item)+" ")]):_vm._e()],1),(
						!page.componentName &&
						page.item &&
						!page.item.componentName &&
						index === _vm.currentPageIndex
					)?_c('div',{staticClass:"h-screen w-screen absolute",attrs:{"has-sound":_vm.hasSound},on:{"nextpage":function($event){return _vm.updateCurrentPage('next')}}},[_vm._v(" "+_vm._s(page.item)+" ")]):_vm._e()],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }